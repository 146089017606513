import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';

import { capitalize } from '../../utils/string-functions';
import LinkButton from '../buttons/link-button';

function TargetAudience({ title, body, link, image, alt }) {
  return (
    <Link to={`/${link}`}>
      <Flex
        justify="start"
        align="start"
        w="100%"
        h="100%"
        p={8}
        borderRadius="xl"
        bgColor="white"
        boxShadow="md"
        cursor="pointer"
        transition="all .1s ease-out"
        _hover={{ boxShadow: 'xl', transform: 'translateY(-5px)' }}
      >
        <Flex
          flexDir={{ base: 'column', md: 'row', lg: 'column' }}
          flexGrow={1}
        >
          <Box
            w={{ base: '100%', md: '40%', lg: '100%' }}
            borderRadius="md"
            overflow="hidden"
            height="10rem"
            mb={{ base: 12, md: 0, lg: 12 }}
            maxH={{ base: '10rem', md: '20rem' }}
            alignSelf={{ base: 'stretch', md: 'center', lg: 'stretch' }}
            ml={{ base: 0, md: 10, lg: 0 }}
            order={{ base: 1, md: 3, lg: 1 }}
          >
            <Img
              image={image}
              imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
              alt={alt}
            />
          </Box>
          <Flex
            flexGrow={1}
            flexDir="column"
            w={{ base: '100%', md: '70%', lg: '100%' }}
            order={{ base: 3, md: 1, lg: 3 }}
          >
            <Heading as="h2" size="md" fontWeight="semibold">
              {capitalize(title)}
            </Heading>
            <Text color="gray.600" my={3}>
              {body}
            </Text>
            <LinkButton
              text={`View courses for ${title}`}
              link="courses"
              noUnderline
            />
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
}

export default TargetAudience;
